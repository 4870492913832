
.checkbox-sub-container {
    display: flex;
    height: 30px;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
}

.checkbox-sub-container:hover {
    cursor: pointer;
}

.mycheckbox {
    margin: 10px;
}

.mycheckbox label {
    font-size: 20px;
    color: var(--dark-gray);
    border-radius: 10px 0px 0px 10px;
    padding: 0px 20px;
    display: inline-block;
    height: 30px;
    vertical-align: middle;
    line-height: 30px;
    background-color: var(--background-gray);
    transition: .5s;
}

.checkbox-container {
    display: inline-block;
    height: 30px;
    vertical-align: middle;
    padding: 0px 20px;
    border-radius: 0px 10px 10px 0px;
    transition: .5s;
}

.checkbox-checked {
    background-color: var(--main-color);
    color: white;
}

.checkbox-unchecked {
    color: white;
    background-color: #bbb;
}

.checkbox {
    display: inherit;
}

@media only screen and (max-width: 1000px) {
    .checkbox-container {
        border-radius: 10px;
    }
    .mycheckbox label {
        border-radius: 10px;
    }
}