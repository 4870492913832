.trello-result-container {
    margin: 20px auto;
    text-align: left;
    background-color: var(--background-gray);
    padding: 0px 30px;
    border-radius: 12px;
    border: 1px solid var(--accent-gray);
    box-shadow: none;
    transition: .5s;
}
  
.trello-result-container:hover {
    box-shadow: 2px 2px 5px var(--accent-gray);
    transition: .5s;
}
  
.trello-result-container h2 {
    margin: 0px 20px 0px 0px;
    color: var(--text-gray);
    text-decoration: none;
    font-weight: normal;
}
  
.trello-result-container .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
    font-size: 15px;
}
  
.trello-result-container .board {
    background-color: var(--accent-color);
    color: white;
    padding: 5px 15px;
    border-radius: 10px;
    font-size: 15px;
    min-width: 100px;
    text-align: center;
}
  
.tag-container {
    text-align: left;
    margin: 20px 0px;
}
  
.trello-result-container .tag {
    text-align: center;
    margin: 5px 10px 5px 0px;
    background-color: var(--accent-gray);
    color: var(--accent-color);
    padding: 5px 15px;
    border-radius: 10px;
    font-size: 14px;
    white-space: nowrap;
    display: inline-block;
}
  
.trello-result-container .sub-header {
    margin: 0px 0px 10px 0px;
    color: #aaa;
    font-style: italic;
}
  
.trello-result-container .description {
    background-color: #fcfcfc;
    padding: 5px 15px;
    border: 1px solid var(--accent-gray);
    border-radius: 5px;
    overflow-y: auto;
    margin-bottom: 20px;
}
  
.trello-result-container .description em {
    background-color: yellow;
    font-style: normal;
}
  
.trello-result-container .options {
    text-align: center;
}
  
.trello-result-container .options button {
    font-size: 15px;
    background-color: #888;
    border: 2px solid #888;
    margin-bottom: 20px;
}

.trello-container {
    width: 75%;
    padding: 20px;
    border-top: none;
    margin: 0px auto;
    text-align: left;
    max-width: 1400px;
    min-width: 890px;
    margin-bottom: 200px;
}

.trello-header {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

.trello-header h1 {
    text-align: center;
    font-size: 30px;
    margin:0px;
}

.trello-ticket-container {
    background-color: white;
    margin: 20px 0px;
    border: 1px solid var(--accent-gray);
    padding: 0px 10px 0px 10px;
}

.ticket-options {
    text-align: right;
    margin: 10px 10px;
}

.trello-delete {
    background-color: var(--accent-orange);
    color: white;
    padding: 5px 15px;
    border-radius: 15px;
    font-size: 18px;
    min-width: 100px;
    text-align: center;
}

.trello-delete:hover {
    background-color: var(--accent-color);
    transition: 0.5s;
}

.ticket-input input {
    padding:10px;
}
