.create-resource-container {
    margin-top: 30px;
}

.create-resource-container h1 {
    text-align: center;
}

.form-title-container {
    text-align: center;
    margin: 0px auto 30px auto;
    width: 80%;
}

.form-title-container input {
    font-size: 25px;
    width: 100%;
    text-align: center;
}

.form-field-container {
    margin: 0px auto 40px auto;
    width: 70%;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.form-field-container input {
    font-size: 15px;
    flex-grow: 3;
}

.form-field-container textarea {
    flex-grow: 3;
}