.results-options-container {
    position: sticky;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    background-color: var(--accent-gray);
    align-items: center;
  }

.main-options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
    background-color: #ddd;
}

.main-options button {
    margin: 0px 5px;
    border: none;
    color: var(--accent-color);
    border-radius: 5px;
    padding: 10px 10px;
    font-size: 15px;
    box-shadow: 2px 2px 5px #888;
}

.main-options .selected {
    background-color: var(--accent-color);
    color: white;
    box-shadow: none;
}

.secondary-options {
    background-color: var(--background-gray);
    padding:10px;
}

.secondary-options button {
    border: none;
    padding: 5px 10px;
    margin: 0px 5px;
    border-radius: 5px;
    background-color: var(--accent-gray);
    color: var(--accent-color);
    box-shadow: 2px 2px 3px #888;
}

.back-option-button {
    background-color: inherit;
    border: none;
    color: var(--accent-color);
    margin-left: 20px;
    font-size: 15px;
    vertical-align: middle;
}

.refresh-option-button {
    background-color: inherit;
    border: none;
    color: var(--accent-color);
    margin-right: 20px;
    font-size: 15px;
    vertical-align: middle;
}
.sticky-back-to-top button {
    border: none;
    background-color: var(--main-color);
    color: white;
    border-radius: 100px;
}

.sticky-back-to-top {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 20px;
}