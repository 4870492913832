.switch-container {
    text-align: right;
}

.switch-option {
    display:inline-block;
}

.switch-option button {
    color: var(--accent-gray);
    border: 2px solid white;
    background-color: var(--background-gray);
    transition: .5s;
    font-size: 15px;
    padding: 5px 10px;
}

.switch-on {
    color: white !important;
    background-color: var(--error-msg) !important;
    border: 2px solid var(--error-msg) !important;
}

.switch-off {
    color: white !important;
    background-color: var(--main-color) !important;
    border: 2px solid var(--main-color) !important;
}