.manage-items-container {
    margin: 0px auto;
    width: 60%;
    max-width: 500px;
    min-width: 400px;
    text-align: center;
    padding: 20px;
}

.options-container {
    max-height: 60vh;
    overflow-y: auto;
    border: 2px solid var(--accent-gray);
    border-radius: 10px;
    padding: 10px;
}

.manage-items-container .option {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid var(--main-color);
    padding: 10px;
}

.manage-items-container label {
    display: inline-block;
}

.manage-items-container button {
    display: inline-block;
    margin: 0px 5px;
}

.manage-items-container .create-new {
    font-size: 20px;
    margin: 20px;
}