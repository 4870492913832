.message-container {
    text-align: left;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    width: 300px;
    padding: 15px 20px;
    box-shadow: 2px 2px 3px #888;
    animation: slide-in ease-in-out .3s;
    margin: 5px 0px;
}

.messages-container {
    display: flex;
    justify-content: right;
    flex-direction: column;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000000;
}

.message-container:hover {
    cursor: pointer;
}

.message-container h1 {
    margin: 0px auto;
}

.message-container p {
    margin: 0px;
}

.error-message {
    background-color: var(--error-msg);
}

.info-message {
    background-color: var(--main-color);
}

@keyframes slide-in {
    0% {
        opacity: .2;
    }
    50% {
        opacity: .8;
    }
    100% {
        opacity: 1
    }
}