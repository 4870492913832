html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-color: #0aa89a;
  --accent-gray: #dedede;
  --dark-gray: #888;
  --accent-color: #355691;
  --error-msg: #AB4E68;
  --background-gray: #f7f7f7;
  --accent-orange: #F55765;
  --text-gray: #4a4a4a;
}

.hover-cursor:hover {
  cursor: pointer;
}

input {
  border: none;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-bottom: 1px solid var(--accent-gray);
}

input:focus {
  outline: none;
}

.main-button {
  border: none;
  font-size: 18px;
  background-color: var(--main-color);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 2px 2px 3px #888;
  margin: 5px;
  box-sizing: border-box;
  border: 2px solid var(--main-color);
  min-width: 100px;
}

.secondary-button {
  border: none;
  font-size: 18px;
  border: 2px solid #aaa;
  background-color: #aaa;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 2px 2px 3px #888;
  box-sizing: border-box;
  margin: 5px;
}

.main-button:active .secondary-button:active {
  box-shadow: none;
}

button:hover {
  cursor: pointer;
}

.search-result-container {
  margin: 20px auto;
  text-align: left;
  background-color: var(--background-gray);
  padding: 0px 30px;
  border-radius: 12px;
  border: 1px solid var(--accent-gray);
  box-shadow: none;
  transition: .5s;
  max-width: 1000px;
}

.search-result-container:hover {
  box-shadow: 2px 2px 5px var(--accent-gray);
  transition: .5s;
}

.search-result-container h2 {
  margin: 0px 20px 0px 0px;
  color: var(--text-gray);
  text-decoration: none;
  font-weight: normal;
}

.search-result-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  font-size: 15px;
}

.search-result-container .category {
  background-color: var(--accent-color);
  color: white;
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 15px;
  min-width: 100px;
  text-align: center;
}

.tag-container {
  text-align: left;
  margin: 20px 0px;
}

.search-result-container .tag {
  text-align: center;
  margin: 5px 10px 5px 0px;
  background-color: var(--accent-gray);
  color: var(--accent-color);
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 14px;
  white-space: nowrap;
  display: inline-block;
}

.search-result-container .sub-header {
  margin: 0px 0px 10px 0px;
  color: #aaa;
  font-style: italic;
}

.search-result-container .description {
  background-color: #fcfcfc;
  padding: 5px 15px;
  border: 1px solid var(--accent-gray);
  border-radius: 5px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.search-result-container .description em {
  background-color: yellow;
  font-style: normal;
}

.search-result-container .options {
  text-align: center;
}

.search-result-container .options button {
  font-size: 15px;
  background-color: #888;
  border: 2px solid #888;
  margin-bottom: 20px;
}

.link-container {
  color: unset;
  text-decoration: unset;
  margin: 0px 5px;
}

.bold {
  font-weight: bold;
}

.commenter {
  font-weight: 800;
  margin: 10px 0px 5px 0px;
}

.internal-flag {
  font-style: normal;
  color: var(--error-msg);
  padding: 3px 5px;
  border-radius: 5px;
  margin-left: 5px;
  font-size: 20px;
  vertical-align: middle;
}

select {
  font-size: 15px;
  padding: 10px;
  background-color: var(--background-gray);
  border: 1px solid var(--accent-gray);
  border-radius: 10px;
  width: 200px;
}



@media only screen and (max-width: 600px) {
  .search-result-container .category {
      margin-top: 20px;
  }
  .search-result-container .header {
    flex-direction: column;
    align-items: baseline;
  }
  /* .tag-container {
    display: none;
  } */
}  