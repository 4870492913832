.comment-editor-container {
    width: 85%;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: white;
    border: 2px solid var(--accent-gray);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 10000;
    text-align: center;
}

.replies-container {
    padding: 5px 0px 5px 30px;
    border-left: 3px solid var(--accent-gray);
    margin: 20px 10px 20px 30px;
  }

.comments-container {
    width: 75%;
    padding: 20px;
    border-top: none;
    margin: 0px auto;
    text-align: left;
    max-width: 1400px;
    margin-bottom: 200px;
}

.comments-header {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

.comments-header h1 {
    text-align: center;
    font-size: 30px;
    margin:0px;
}

.comment-container {
    background-color: white;
    margin: 20px 0px;
    border: 1px solid var(--accent-gray);
}

.comment-header {
    margin: 0px;
    padding: 10px;
    background-color: var(--accent-color);
    color: white;
}

.comment-subheader {
    background-color: var(--background-gray);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.comment-subheader p {
    font-style: italic;
    margin: 0px;
    padding: 10px;
}

.replies-label {
    text-align: center;
    margin:0px;
    background-color: var(--background-gray);
  }

.replies-label button {
    border: none;
    padding: 10px 15px;
    background-color: inherit;
    width: 100%;
    font-weight: bold;
}

.comment-options {
    text-align: right;
    margin: 10px 10px;
}

.comment-options button {
    font-size: 14px;
    padding: 5px 10px;
    background-color: var(--accent-gray);
    border: none;
    box-shadow: 1px 1px 1px #888;
    color: black;
}

.comment-editor-expand {
    background: none;
    border: none;
    font-size: 18px;
    margin: 5px;
    line-height: 13px;
  }