.edit-title-container {
    text-align: center;
    margin: 0px auto 30px auto;
    width: 50%;
}

.edit-title-container input {
    font-size: 25px;
    width: 100%;
    text-align: left;
    border-radius: 5px;
}

.edit-dropdown-container {
    margin: 0px auto 40px auto;
    width: 50%;
    text-align: left;
}

.file-upload-container {
    width: 50%;
    margin: 10px auto;
}