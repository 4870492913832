.notification-setting {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
}

.notification-settings {
    width: 50%;
    margin: 30px auto;
}

.notification-subscriptions {
    max-height: 400px;
    overflow: auto;
    border: 1px solid var(--accent-gray);
    border-radius:10px;
    padding: 10px 0px;
}

.notification-sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

.notification-viewer {
    width: 500px;
}

.notification-viewer-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 60vh;
    overflow-y: auto;
    padding: 20px;
    border-radius: 0px 0px 10px 10px;
    overflow-x: hidden;
}

.notification-container {
    border: 1px solid var(--accent-gray);
    width: 100%;
    margin: 20px;
    min-width: 450px;
    max-width: 600px;
    text-align: center;
    color: var(--text-gray) !important;
}

.unread-notification-container {
    border-left: 10px solid var(--accent-color);
}

.notification-header {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    align-items: center;
    background-color: var(--accent-gray);
}

.unread-header {
    background-color: var(--accent-gray);
}

.notification-header h3 {
    line-height: 30px;
    display: flex;
    align-items: center;
}

.notification-viewer-header {
    padding: 10px;
    border-bottom: 2px solid var(--background-gray);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.unread-notification-indicator {
    width: 20px;
    height: 20px;
    background-color: var(--accent-color);
    display: inline-block;
    margin-right: 10px;
    border-radius: 100px;
    box-shadow: 1px 1px 3px var(--text-gray);
}

.read-notification-indicator {
    width: 20px;
    height: 20px;
    background-color: white;
    display: inline-block;
    margin-right: 10px;
    border-radius: 100px;
    border: 1px solid #888;
}

.icon-container {
    width: 35px;
    height: 35px;
    background-color: var(--background-gray);
    border-radius: 5px;
    padding: 2px 5px;
}
.icon-container:hover {
    cursor: pointer;
}

.notifications-nav-dropdown {
    position: absolute;
    right: 20px;
    z-index: 50000;
    background-color: white;
    border: 2px solid var(--accent-gray);
    border-radius: 10px;
    box-shadow: 3px 3px 5px var(--dark-gray);
    margin-top: 5px;
}

.notification-sub a {
    text-decoration: none;
    color: black;
    font-weight: bold;
}

.dismiss-all-button {
    background-color: var(--main-color);
    border: none;
    border-radius: 10px 10px 10px 10px;
    color: #fff;
    cursor: pointer;
    font-size: 1.2em;
    font-weight: 600;
    height: 30px;
    margin: auto 0;
    padding: 0px 20px;
    transition: .5s;
    vertical-align: middle;
}

.dismiss-all-button:hover {
    background-color: var(--accent-color);
}
