.report-object {
    width: 50%;
    margin: 20px auto;
    text-align: left;
    border: 2px solid var(--accent-gray);
    padding: 5px 20px;
    background-color: var(--background-gray);
    border-radius: 10px;
}

.reports-container {
    text-align: center;
}

.report-title {
    text-align: center;
    font-size: 25px;
    color: var(--main-color);
}