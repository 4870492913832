.options-header {
    width: 100%;
    margin:0px auto;
    text-align: center;
    background-color: var(--background-gray);
    padding: 5px 0px;
    position: sticky;
    z-index: 1500;
}

.options-header button {
    font-size: 15px;
}

.view-article-category {
    padding: 5px 15px;
    background-color: var(--accent-color);
    color: white;
    font-weight: bold;
    border-radius: 10px;
    margin-right: 20px;
    font-size: 15px;
    vertical-align: middle;
}

.view-article-title {
    font-size: 30px;
    margin:20px;
   
}

.view-article-header {
    padding-bottom: 10px;
    border-bottom: 2px solid var(--background-gray)
}

.tags {
    padding: 15px 10px;
    text-align: left;
    margin: 0px auto;
}

.tags span {
    padding: 5px 10px;
    background-color: var(--accent-color);
    color: white;
    margin: 10px;
    border-radius: 10px;
}

.view-article-container {
    margin: 0px auto;
    width: 75%;
    min-width: 400px;
    max-width: 1700px;
    border-left: 2px solid var(--background-gray);
    border-right: 2px solid var(--background-gray);
}

.article-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.article-body-container {
    display: flex;
    /* border-bottom: 2px solid var(--background-gray); */
    padding-bottom: 40px;
}

.article-anchor {
    scroll-margin: 200px;
}

.user-options {
    margin: 0px 0px 10px 20px;
    display: flex;
    column-gap: 20px;
}

.user-option {
    border: none;
    padding: 0px; 
    background-color: transparent;
}

.user-option-icon-on {
    width: 40px; 
    height: 40px; 
    padding: 10px;
    color: var(--main-color);
    background-color: var(--accent-gray); 
    border-radius: 10px;
}

.user-option-icon-off {
    width: 40px; 
    height: 40px; 
    padding: 10px;
    color: var(--error-msg);
    background-color: var(--accent-gray); 
    border-radius: 10px;
}

.user-option-icon:hover{
    cursor: pointer;
    opacity: 90%;
}

.selected-extras-option {
    color: var(--accent-color) !important;
    background-color: var(--background-gray) !important;
    border: 1px solid var(--accent-gray) !important;
    font-weight: bold !important;
  }

.article-extras-option {
    background-color: white;
    border: none;
    padding: 10px 25px;
    font-size: 18px;
    color: var(--dark-gray);
    margin: 0px 10px;
    border-radius: 10px;
    transition: .5s;
}

.article-extras-options {
    width: 80%;
    margin: 20px auto;
    border-bottom: 2px solid var(--background-gray);
    padding-bottom: 20px;
}

@media only screen and (max-width: 600px) {
    .article-title-container {
        flex-direction: column;
        align-items: baseline;
    }
    .view-article-category {
        margin: 0px 0px 20px 20px;
    }
} 
