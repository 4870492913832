.home-page-content {
    overflow-x:hidden;
}
    
.recent-articles {
    text-align:center;
    min-height:500px;
    background-color:#fff;
    width: 90%;
    margin: 0px auto;
}
    
.favorite-articles {
    text-align:center;
    background-color:#fff;
    width: 90%;
    margin: 0px auto;
}

.row {
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    width:100%;
}

.column {
    display:flex;
    flex-direction:column;
    flex-basis:100%;
    flex:1;
    text-align:center;
}

.home-banner {
    width:100%;
    background-color:#4464AD;
    color:#fff;
    text-align:center;
    padding:15px 0;
    min-height: 425px;
    background-image: linear-gradient(var(--accent-color), #4464ad 20%, var(--background-gray) 99%);
}

.home-banner-content {
    color:#fff;
    width:100%;
    font-size:50px;
    padding:20px;
    font-weight: bold;
    margin-top: 20px;
}

.advanced-search-link {
    margin-top: 10px;
}

.advanced-search-link > a {
    cursor:pointer;
    text-decoration:underline;
    font-size:15px;
    font-weight:500;
    color:#fff;
    transition:.5s;
    vertical-align: middle;
}

.recently-added-header {
    color:#000;
    width:100%;
    font-size:1.7em;
    font-weight:bolder;
    padding:20px;
}

.recent-articles-list {
    margin:20px auto;
}

.search {
    box-sizing:border-box;
    vertical-align:top;
    padding:0 30px;
    font-size:18px;
    width:60%;
    max-width:600px;
    height:40px;
    border-radius:10px 0 0 10px;
    border-bottom: 0px;
}

.home-search-input {
    text-align:center;
    width:100%;
    padding:10px 0;
}

.submit {
    cursor:pointer;
    border:none;
    background-color:var(--main-color);
    color: #fff;
    border-radius:0 10px 10px 0;
    display:inline-block;
    width:150px;
    height:40px;
    font-size:1.2em;
    font-weight:700;
    transition:.3s;
}

.submit:hover {
    font-size: 21px;
}

.home-footer {
    width:100%;
    background-color:#4464AD;
    color:#fff;
    text-align:center;
    align-items:center;
    padding:30px;
    padding-bottom:50px;
}

button > a {
    color:#fff;
    text-decoration:none;
}

.quick-link-category {
    display: inline-block;
    margin: 10px;
    background-color: var(--accent-color);
    border-radius: 10px;
    min-width: 200px;
    box-shadow: 1px 1px 3px #888;
    transition: .5s;
}

.quick-link-category:hover {
    cursor: pointer;
    box-shadow: none;
}

.quick-link-category a {
    text-decoration: none;
    color: white;
}

.quick-link-category p {
    font-size: 13px;
}

.quick-categories {
    margin: 0px auto;
    text-align: center;
    padding: 30px 0px;
    background-color: var(--background-gray);
    color: white;
    font-weight: bold;
    background-image: linear-gradient(var(--background-gray), white);
    margin-bottom:50px;
    border-bottom: 1px solid var(--background-gray)
}

.section-title {
    margin: 0px auto;
    margin-bottom: 0px;
    color: var(--text-gray);
    margin-bottom: 20px;
    font-size: 35px;
    font-weight: normal;
  }

.main-category {
    display: block;
    padding:5px;
    background-color: var(--main-color);
    margin:0px auto;
    margin-top: 20px;
    width:70%;
    max-width: 450px;
}

.main-category p {
    font-size: 18px;
}

@media only screen and (max-width: 600px) {
    .search, .submit {
        border-radius: 10px;
        margin: 5px 0px;
    }

    .search {
        width: 90%;
    }

} 