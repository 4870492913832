.login-container {
    margin: 20px auto;
    text-align: center;
    width: 500px;
    background-color: var(--background-gray);
    padding: 20px 0px;
    border-radius: 20px;
    border: 5px solid var(--accent-gray);
    max-width: 90%;
} 

.login-container h1 {
    margin: 0px 0px 70px 0px;
    color: var(--main-color);
}

@media (max-width: 500px) {
    .login-container {
        width: auto;
    } 
} 