@keyframes colorChange {
    0% {
        fill: var(--main-color);
    }
    50% {
        fill: var(--accent-gray);
    }
    100% {
        fill: var(--main-color);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1.2);
    }
    50% {
        transform: scale(1.0);
    }
    100% {
        transform: scale(1.2);
    }
}

.svg-container {
    animation: pulse 2s ease infinite;
}

#iris {
    animation: colorChange 2s ease infinite;
}

#pupil {
    transform: scale(1.2);
}

#eyelid {
    /* transform: scale(.9); */
    animation: colorChange 3s ease infinite;
}

#eyeball {
    animation: colorChange 3s ease infinite;
}