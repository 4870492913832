.codeblock {
    position:relative
}

.codeblock-dropdown-container {
    position: absolute;
    left: 0px;
    top: 30px;
    z-index: 100;
    background-color: white;
    border: 2px solid black;
    padding: 0px 10px;
}