.nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--accent-color);
    border-width: 15px 0px;
    border-color: var(--accent-color);
    border-style: solid;
    position: sticky;
    top: 0;
    z-index: 2000;
  }
  
  .nav-link {
    margin: 0px 5px;
    transition: .3s;
  }
  
  .nav-link a {
    color: white;
    text-decoration: none;
    padding: 10px 20px;
  }
  
  .nav-link:hover {
    color: var(--main-color) !important;
    border-radius: 8px;
  }
  
  .nav-header {
    text-align: center;
    color: white;
    padding-left: 20px;
    font-weight: 900;
    font-size: 1.5em;
  }
  
  .selected-link {
    background-color: white;
    color: var(--accent-color) !important;
    border-radius: 5px;
  }
  
  .not-selected-link:hover {
    background-color:var(--main-color);
    color: white;
    border-radius: 8px;
  }
  
  .left-side, .right-side {
    display: flex;
    flex-grow: 50%;
    flex-shrink: 0;
    align-items: center;
  }
  
  .right-side {
    justify-content: right;
  }
  
  .logout-button {
    border: none;
    background-color: var(--accent-orange);
    color: white;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 1.05em;
    margin: 0px 5px;
  }
  
  .logout-button:hover {
    background-color: white;
    color: var(--main-color);
    transition: 0.3s ease-in;
  }

.hamburger-container {
    margin-right: 20px;
    color: white;
}

.hamburger-container:hover {
    cursor: pointer;
}

.slide-out-backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.7);
    top: 0;
    z-index: 20000;
  }

.slide-out-container {
    position: fixed;
    height: 100vh;
    right: 0;
    width: 40%;
    background-color: var(--background-gray);
    width: 350px;
    transition: .3s ease;
    max-width: 80%;
}

.hide-slide-out-container {
    width: 0px;
    transition: .3s ease;
    right: 0;
    position: fixed;
}

.slide-out-option {
    text-align: left;
    border-bottom: 2px solid var(--accent-gray);
}

.slide-out-option a {
    padding: 20px;
    width: 100%;
    display: inline-block;
    text-decoration: none;
    color: black;
}

.slide-out-option a:visited {
    color: inherit;
}   

.slide-out-option a:active {
    color: inherit;
}   

.selected-slide-link {
    background-color: var(--main-color);
    color: white !important;
    font-weight: bold;
}

.not-selected-slide-link:hover {
    background-color: var(--accent-gray);
}

.close-option {
    padding: 10px;
    background-color: var(--accent-color);
    text-align: right;
}

.close-button {
    border: none;
    background-color: inherit;
    color: white;
}

.hide-side-bar {
    visibility: hidden;
}

.slide-out-logout-button {
    border: none;
    background-color: var(--accent-orange);
    width: 60%;
    margin: 10px;
    padding: 10px 0px;
    border-radius: 15px;
    font-size: 20px;
    color: white;
}

@media only screen and (max-width: 1100px) {
    .browser-nav {
        display: none;
    }
}  

@media only screen and (min-width: 1100px) {
    .mobile-nav {
        display: none;
    }
}  

@media only screen and (max-width: 1100px) {
    .nav-header {
        font-size: 15px;
    }
}  