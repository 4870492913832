.generic-modal-backdrop {
    background-color: rgba(255, 255, 255, 0.7);
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    text-align: center;
    z-index: 50000;
}

.generic-modal-container {
    background-color: white;
    width: 60%;
    margin: 5% auto;
    border-radius: 10px;
    border: 1px solid var(--main-color);
}

.generic-modal-header {
    text-align: left;
    padding: 10px 10px;
    background-color: var(--accent-color);
    color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.generic-modal-body {
    margin: 10px 15px;
}

.generic-modal-header h1 {
    margin: 0px auto;
}

.generic-modal-footer {
    padding: 20px;
}

