.search-results-table {
    text-align: left;
    width: 100%;
}

.search-results-table th {
    padding: 5px;
    background-color: var(--main-color);
    color: white;
}

.search-results-table td {
    padding: 5px 15px;
}

.search-results-table .limit-width {
    max-width: 200px;
    word-wrap: break-word;
}

.search-results-table .dark {
    background-color: #f5f5f5;
}

.search-results-table a:visited {
    color: black;
}

.search-results-table a:hover {
    color: #888;
}

.search-results-table a {
    color: black;
}

.results-container {
    overflow-y: auto;
    max-height: 70vh;
}

.article-result {
    margin: 20px 10px;
    text-align: left;
    background-color: var(--background-gray);
    padding: 0px 30px;
    border-radius: 12px;
    border: 1px solid var(--accent-gray);
    box-shadow: 2px 2px 5px var(--accent-gray);
}

.articles-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

@media only screen and (max-width: 1000px) {
    .articles-grid-container {
        display:block;
    }
}