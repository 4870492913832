.article-outline-container {
    background-color: white;
    padding: 20px;
    position: sticky;
    top: 100px;
    align-self: flex-start;
    max-height: 75vh;
    overflow-y: auto;
    width:400px;
}

.article-outline-container a {
    text-decoration: none;
    color: black;
}

.outline-title {
    font-weight: bold;
    font-size: 17px;
    margin: 10px 0px;
    padding: 10px 5px;
    border-radius: 10px;
    background-color: var(--background-gray);
    box-shadow: 3px 3px 5px var(--accent-gray);
}

.outline-title a {
    color: var(--text-gray);

}

.outline-section-title {
    margin: 10px;
    border-bottom: 2px solid var(--accent-gray);
    padding: 15px 5px;
}

.outline-section-title a {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.outline-section-subtitle a {
    color: var(--dark-gray);
    font-style: italic;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.outline-section-subtitle {
    margin: 5px 30px;
}

.flex-title {
    display: flex;
    align-items: center;
}

.outline-subtitle {
    background-color: var(--background-gray);
    border-radius: 10px;
    border: 1px solid var(--accent-gray);
    margin: 20px 0px;
    padding: 15px 15px;
}

.outline-subtitle a {
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}


@media only screen and (max-width: 800px) {
    .article-outline-container {
        display: none
    }
    .view-article-category {
        margin: 0px 0px 20px 20px;
    }
} 