.search-container {
    margin: 0px auto;
    text-align: center;
}

.search-container .query {
    text-align: center;
    width: 70%;
    margin: 20px auto;
    background-color: white;
    border-radius: 10px;
    box-shadow: 2px 2px 5px #888;
    padding-bottom: 20px;
    min-width: 400px;
    max-width: 1000px;
}

.bottom-sticky-options {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 10px 0px;
    background-color: var(--background-gray);
  }

.form-table {
    text-align: left;
    width: 100%;
    margin:0px auto;
}

.search-query-header input {
    width: 70%;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px;
    border-color: black;
    font-size: 20px;
}

.search-query-header h2 {
    text-align: left;
    background-color: var(--accent-color);
    color:white;
    margin-bottom: 0px;
    padding:10px;
    border-radius: 10px 10px 0px 0px;
}

.search-query-body {
    padding: 20px 0px;
    height:200px;
}

.search-query-label {
    font-size: 20px;
    margin: 0px;
}

.search-query-entry {
    display: flex;
    width: 70%;
    margin: 0px auto;
    justify-content: space-between;
    align-items: center;
}

.search-tool-selection {
    padding-bottom: 15px;
    justify-content: center;
    border-bottom: 2px solid var(--accent-gray);
    width: 100%;
}

.search-subnav {
    transition: .5s;
}

.search-subnav button {
    margin: 10px;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 2px 2px 2px var(--accent-gray);
    transition: .3s;
}

.selected-search-subnav {
    box-shadow: none !important;
    background-color: var(--accent-color);
    color:white;
    font-weight: bold;
}

@media only screen and (max-width: 1100px ) {
    .search-tool-selection  {
        flex-direction: column;
        align-items: baseline;
        width: 70%;

    }
} 