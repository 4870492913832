.file-lister-container {
    margin: 0px 10px;
    text-align: left;
  }

.file-object {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: var(--background-gray);
    margin: 10px 10px 10px 0px;
}

.file-object button {
  background-color: var(--accent-gray);
  border-radius:3px;
  padding: 5px 10px;
  border: none;
}

.file-object button:hover {
  background-color: #d1d1d1;
}